import Globals from "../../shared/Globals";
import BaseCom from "../BaseCom";

export default class Appointment extends BaseCom {

    // PUBLISH
    // What is actually rendered in the published website
    publish() {
        return (
            <div>
                prod html
            </div>
        );
    }

    // PREVIEW
    // This is what is displayed in the builder
    // Top level element must have:
    // style={Globals.preprocessStyleObject(this.props.col.style)}
    // className={this.props.col.className}
    // You can append additional classes to the className, don't forget to add a space before or after
    render() {
        return (
            <div className={'text-center p-4 bg-light ' + this.props.col.className}
                style={Globals.preprocessStyleObject(this.props.col.style)}>
                <label className="form-label">Enter your Zip Code</label>
                <input type="text" className="form-control" />
            </div>
        );
    }
}